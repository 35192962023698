import i18n from '@/shared/i18n/i18n';
import { CasinoGame, CasinoSortType } from './types';

export const casinoFilterSortTitles: Record<CasinoSortType, string> = {
  default: i18n.t('casino-widget.filter.default', 'По умолчанию'),
  popular: i18n.t('casino-widget.filter.popular', 'По популярности'),
  new: i18n.t('casino-widget.filter.new', 'По новизне'),
  ascending: i18n.t('casino-widget.filter.ascending', 'По алфавиту A-Z'),
  descending: i18n.t('casino-widget.filter.descending', 'По алфавиту Z-A'),
};

export const casinoSortList: CasinoSortType[] = [
  'default',
  // 'popular',
  // 'new',
  'ascending',
  'descending',
];

export const fastSportList: CasinoGame[] = [
  {
    id: '51',
    gameId: '51',
    name: 'Short Football',
    link: '/games/fastsport/51',
    isFavorite: false,
    preview: '/img/games/fastsport/football@500х300.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'fastsport',
    mainProvider: 'fastsport',
    hasFreeSpins: false,
  },
  {
    id: '52',
    gameId: '52',
    name: 'Table tennis',
    link: '/games/fastsport/52',
    isFavorite: false,
    preview: '/img/games/fastsport/tennis@500х300.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'fastsport',
    mainProvider: 'fastsport',
    hasFreeSpins: false,
  },
  {
    id: '53',
    gameId: '53',
    name: 'Cyberfootball',
    link: '/games/fastsport/53',
    isFavorite: false,
    preview: '/img/games/fastsport/efootball@500х300.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'fastsport',
    mainProvider: 'fastsport',
    hasFreeSpins: false,
  },
];

export const tvbetList: CasinoGame[] = [
  {
    id: '10',
    gameId: '10',
    name: '1Bet',
    link: '/games/tvbet/10',
    isFavorite: false,
    preview: '/img/games/tvbet/onebet.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'tvbet',
    mainProvider: 'tvbet',
    hasFreeSpins: false,
  },
  {
    id: '12',
    gameId: '12',
    name: 'Lucky6',
    link: '/games/tvbet/12',
    isFavorite: false,
    preview: '/img/games/tvbet/bet6.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'tvbet',
    mainProvider: 'tvbet',
    hasFreeSpins: false,
  },
  {
    id: '2',
    gameId: '2',
    name: 'Wheelbet',
    link: '/games/tvbet/2',
    isFavorite: false,
    preview: '/img/games/tvbet/wheel.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'tvbet',
    mainProvider: 'tvbet',
    hasFreeSpins: false,
  },
  {
    id: '6',
    gameId: '6',
    name: '5Bet',
    link: '/games/tvbet/6',
    isFavorite: false,
    preview: '/img/games/tvbet/lucky5.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'tvbet',
    mainProvider: 'tvbet',
    hasFreeSpins: false,
  },
  {
    id: '7',
    gameId: '7',
    name: '7Bet',
    link: '/games/tvbet/7',
    isFavorite: false,
    preview: '/img/games/tvbet/lucky7.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'tvbet',
    mainProvider: 'tvbet',
    hasFreeSpins: false,
  },
  {
    id: '9',
    gameId: '9',
    name: 'Keno',
    link: '/games/tvbet/9',
    isFavorite: false,
    preview: '/img/games/tvbet/keno.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'tvbet',
    mainProvider: 'tvbet',
    hasFreeSpins: false,
  },
  {
    id: '23',
    gameId: '23',
    name: 'Fast Keno',
    link: '/games/tvbet/23',
    isFavorite: false,
    preview: '/img/games/tvbet/fastkeno.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'tvbet',
    mainProvider: 'tvbet',
    hasFreeSpins: false,
  },
  {
    id: '19',
    gameId: '19',
    name: 'BlackJack',
    link: '/games/tvbet/19',
    isFavorite: false,
    preview: '/img/games/tvbet/blackjack.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'tvbet',
    mainProvider: 'tvbet',
    hasFreeSpins: false,
  },
  {
    id: '3',
    gameId: '3',
    name: 'Poker',
    link: '/games/tvbet/3',
    isFavorite: false,
    preview: '/img/games/tvbet/poker.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'tvbet',
    mainProvider: 'tvbet',
    hasFreeSpins: false,
  },
  {
    id: '5',
    gameId: '5',
    name: 'War of Elements',
    link: '/games/tvbet/5',
    isFavorite: false,
    preview: '/img/games/tvbet/warofbets.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'tvbet',
    mainProvider: 'tvbet',
    hasFreeSpins: false,
  },
];

export const elCasinoList: CasinoGame[] = [
  {
    id: '22',
    gameId: '22',
    name: 'Roulette',
    link: '/games/tvbet/22',
    isFavorite: false,
    preview: '/img/games/tvbet/el-casino.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'El Casino',
    mainProvider: 'tvbet',
    hasFreeSpins: false,
  },
];

export const batgamesList: CasinoGame[] = [
  {
    id: '5',
    gameId: '5',
    name: i18n.t('games.betgames.Bet-On-Poker', 'Покер'),
    link: '/games/betgames/5',
    isFavorite: false,
    preview: '/img/games/betgames/bet-games-poker.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'betgames',
    mainProvider: 'betgames',
    hasFreeSpins: false,
  },
  {
    id: '17',
    gameId: '17',
    name: i18n.t('games.betgames.Football-Grid', 'Пенальти'),
    link: '/games/betgames/17',
    isFavorite: false,
    preview: '/img/games/betgames/bet-games-football.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'betgames',
    mainProvider: 'betgames',
    hasFreeSpins: false,
  },
  {
    id: '7',
    gameId: '7',
    name: i18n.t('games.betgames.Wheel-Of-Fortune', 'Колесо Фортуны'),
    link: '/games/betgames/7',
    isFavorite: false,
    preview: '/img/games/betgames/bet-games-wheel.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'betgames',
    mainProvider: 'betgames',
    hasFreeSpins: false,
  },
  {
    id: '12',
    gameId: '12',
    name: i18n.t('games.betgames.6+-Poker', '6+ Покер'),
    link: '/games/betgames/12',
    isFavorite: false,
    preview: '/img/games/betgames/bet-games-6-poker.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'betgames',
    mainProvider: 'betgames',
    hasFreeSpins: false,
  },
  {
    id: '13',
    gameId: '13',
    name: i18n.t('games.betgames.Andar-Bahar', 'Андар Бахар'),
    link: '/games/betgames/13',
    isFavorite: false,
    preview: '/img/games/betgames/bet-games-andar-baha.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'betgames',
    mainProvider: 'betgames',
    hasFreeSpins: false,
  },
  {
    id: '10',
    gameId: '10',
    name: i18n.t('games.betgames.Dice-Duel', 'Дуэль Костей'),
    link: '/games/betgames/10',
    isFavorite: false,
    preview: '/img/games/betgames/bet-games-dice-duel.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'betgames',
    mainProvider: 'betgames',
    hasFreeSpins: false,
  },
  {
    id: '11',
    gameId: '11',
    name: i18n.t('games.betgames.Speedy-7', 'Горячая 7'),
    link: '/games/betgames/11',
    isFavorite: false,
    preview: '/img/games/betgames/bet-games-speedy7.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'betgames',
    mainProvider: 'betgames',
    hasFreeSpins: false,
  },
  {
    id: '8',
    gameId: '8',
    name: i18n.t('games.betgames.War-Of-Bets', 'Битва Ставок'),
    link: '/games/betgames/8',
    isFavorite: false,
    preview: '/img/games/betgames/bet-games-war-of-bets.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'betgames',
    mainProvider: 'betgames',
    hasFreeSpins: false,
  },
  {
    id: '6',
    gameId: '6',
    name: i18n.t('games.betgames.Bet-On-Baccarat', 'Баккара'),
    link: '/games/betgames/6',
    isFavorite: false,
    preview: '/img/games/betgames/bet-games-baccarat.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'betgames',
    mainProvider: 'betgames',
    hasFreeSpins: false,
  },
  {
    id: '16',
    gameId: '16',
    name: i18n.t('games.betgames.Classic-Wheel', 'Колесо Фортуны Классическое'),
    link: '/games/betgames/16',
    isFavorite: false,
    preview: '/img/games/betgames/bet-games-wheel-rng.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'betgames',
    mainProvider: 'betgames',
    hasFreeSpins: false,
  },
  {
    id: '18',
    gameId: '18',
    name: i18n.t('games.betgames.Satta-Matka', 'Сатта Матка'),
    link: '/games/betgames/18',
    isFavorite: false,
    preview: '/img/games/betgames/bet-games-satta-matka.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'betgames',
    mainProvider: 'betgames',
    hasFreeSpins: false,
  },
  {
    id: '3',
    gameId: '3',
    name: i18n.t('games.betgames.Lucky-5', '5 из 36'),
    link: '/games/betgames/3',
    isFavorite: false,
    preview: '/img/games/betgames/bet-games-lucky5.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'betgames',
    mainProvider: 'betgames',
    hasFreeSpins: false,
  },
  {
    id: '9',
    gameId: '9',
    name: i18n.t('games.betgames.Lucky-6', '6 из 60'),
    link: '/games/betgames/9',
    isFavorite: false,
    preview: '/img/games/betgames/bet-games-lucky6.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'betgames',
    mainProvider: 'betgames',
    hasFreeSpins: false,
  },
  {
    id: '1',
    gameId: '1',
    name: i18n.t('games.betgames.Lucky-7', '7 из 42'),
    link: '/games/betgames/1',
    isFavorite: false,
    preview: '/img/games/betgames/bet-games-lucky7.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'betgames',
    mainProvider: 'betgames',
    hasFreeSpins: false,
  },
  {
    id: '26',
    gameId: '26',
    name: 'Instant Lucky 7',
    link: '/games/betgames/26',
    isFavorite: false,
    preview: '/img/games/betgames/card-rng-lucky7.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'betgames',
    mainProvider: 'betgames',
    hasFreeSpins: false,
  },
  {
    id: '27',
    gameId: '27',
    name: 'Skyward',
    link: '/games/betgames/27',
    isFavorite: false,
    preview: '/img/games/betgames/card-skyward.jpg',
    category: [],
    type: ['crash games'],
    provaider: 'betgames',
    mainProvider: 'betgames',
    hasFreeSpins: false,
  },
];

export const twainSportList: CasinoGame[] = [
  {
    id: '24',
    gameId: '24',
    name: i18n.t('games.betgames.T-Kick-24/7', 'Футбол'),
    link: '/games/twain-sport/24',
    isFavorite: false,
    preview: '/img/games/twain-sport/twain-sport-t-kick.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'Twain sport',
    mainProvider: 'Twain sport',
    hasFreeSpins: false,
  },
  {
    id: '25',
    gameId: '25',
    name: i18n.t('games.betgames.T-Basket-24/7', 'Баскетбол'),
    link: '/games/twain-sport/25',
    isFavorite: false,
    preview: '/img/games/twain-sport/twain-sport-t-basket.jpg',
    category: [],
    type: ['tv games'],
    provaider: 'Twain sport',
    mainProvider: 'Twain sport',
    hasFreeSpins: false,
  },
];

export const aviatrixList: CasinoGame[] = [
  {
    id: 'aviatrix',
    gameId: 'aviatrix',
    name: 'Aviatrix',
    link: '/games/aviatrix',
    isFavorite: false,
    preview: '/img/games/vegas/aviatrix_ax.jpg',
    category: [],
    type: ['crash games'],
    provaider: 'aviatrix',
    mainProvider: 'aviatrix',
    hasFreeSpins: false,
  },
];

export const crashGamesType: Record<string, string[] | undefined> = {
  'betgames': ['27'],
  'smartsoft': ['13'],
  'vegas': ['aviator_spribe', 'rocketman_elbet', 'aviatrix_ax'],
  'pragmatic': ['2201', '1301']
};

// {
//   gameId: '',
//     name: '',
//       mainProvider: '',
//   },
