import { CasinoGameCard } from '@/modules/casino';
import Breadcrumbs from '@shared/components/breadcrumbs/breadcrumbs';
import Container from '@shared/components/container/container';
import Pagination from '../../shared/components/pagination/pagination';
import { useAppSelector } from '../../shared/hooks';
import { getCasinoCategories, getCasinoGamesByFilter, getProvidersList } from '@/modules/casino';
import { bemCn } from '@shared/utils/helpers/bem-cn';
import { useFetchGames } from '@/modules/casino';
import Loader from '../../shared/components/loader/loader';
import PromoSection from '../../widgets/core/components/promo-section/promo-section';
import { CasinoFilter } from '@/modules/casino';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import './games-screen.scss';

import type { CasinoSortType } from '@/modules/casino';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useScrollToTop } from '@/shared/hooks/use-scrolltotop';
import CasinoGamesSectionByCategory from '@/modules/casino/components/casino-games-section-by-category/casino-games-section-by-category';
import { useSelector } from 'react-redux';
import { getIsAuth } from '@/modules/auth';
import CasinoBonusesSection from './components/casino-bonuses-section';

const pageSize = 28;

export type CasinoFilter = {
  page?: number;
  provider?: string;
  category?: string;
  search?: string;
  sort?: CasinoSortType;
  favorites?: boolean;
}

const initFilter = {
  provider: 'all',
  category: 'all',
  page: '1',
  search: '',
  sort: 'default',
};

const b = bemCn('games-screen');

const GamesScreen = () => {
  console.log('<GamesScreen />: render');
  useScrollToTop();
  const { t } = useTranslation();
  const { isLoading } = useFetchGames();
  const providers = useAppSelector(getProvidersList);
  const categories = useAppSelector(getCasinoCategories);
  const isAuth = useSelector(getIsAuth);

  const gamesListRef = useRef<HTMLDivElement>(null);

  const [pagePreviewItems, setPagePreviewItems] = useState(pageSize);
  const [searchParams, setSearchParams] = useSearchParams(initFilter);

  const selectedProvider = searchParams.get('provider') ?? 'all';
  const selectedCategory = searchParams.get('category') ?? 'all';
  const search = searchParams.get('search') ?? '';
  const sort = searchParams.get('sort') as CasinoSortType ?? 'ascending';
  const favoritesSelect = Boolean(Number(searchParams.get('favorites')));
  const page = searchParams.get('page')
    ? parseInt(searchParams.get('page') as string, 10)
    : 1;

  const gamesFilter = useMemo(() => ({
    provider: selectedProvider,
    category: selectedCategory,
    favorites: favoritesSelect,
    search,
    sort,
  }), [selectedProvider, selectedCategory, favoritesSelect, search, sort]);

  const gamesList = useAppSelector((state) => getCasinoGamesByFilter(state, gamesFilter));
  const totalGamesCount = gamesList.length;

  const setFilter = (filter: Partial<CasinoFilter>) => {
    setSearchParams((prevParams) => {
      if (filter.page) {
        prevParams.set('page', filter.page.toString());
      }
      if (filter.provider) {
        prevParams.set('provider', filter.provider);
      }
      if (filter.category) {
        prevParams.set('category', filter.category);
      }
      if (filter.sort) {
        prevParams.set('sort', filter.sort);
      }
      if (filter.search !== undefined) {
        prevParams.set('search', filter.search);
      }
      if (filter.favorites !== undefined) {
        prevParams.set('favorites', String(Number(filter.favorites)));
      }

      return prevParams;
    }, { replace: true });

    setPagePreviewItems(pageSize);
  };

  const handleProviderChange = useCallback((newProvider: string) => {
    setFilter({
      provider: newProvider,
      sort: 'default',
      search: '',
      page: 1
    });
  }, []);

  const handleCategoryChange = useCallback((newCategory: string) => {
    let newSsort: CasinoSortType = 'ascending';

    if (newCategory === 'all-providers' || newCategory === 'all') {
      newSsort = 'default';
    }
    setFilter({
      category: newCategory,
      provider: 'all',
      sort: newSsort,
      favorites: false,
      search: '',
      page: 1
    });
  }, []);

  const handleSearchChange = useCallback((newSearchValue: string) => {
    setFilter({
      search: newSearchValue,
      sort: 'ascending',
      favorites: false,
      page: 1
    });
  }, []);

  const handleSortChange = useCallback((newSortValue: CasinoSortType) => {
    setFilter({
      sort: newSortValue,
      search,
      page: 1
    });
  }, []);

  const handleFavoritesChange = useCallback((favorites: boolean) => {
    setFilter({
      category: 'all',
      provider: 'all',
      search,
      favorites,
      page: 1
    });
  }, []);

  const isInfinitScroll = selectedCategory === 'all' && !favoritesSelect;
  const listFrom = isInfinitScroll ? 0 : page * pageSize - pageSize;
  const listTo = isInfinitScroll ? pagePreviewItems : page * pageSize;


  useEffect(() => {
    const handleScroll = () => {
      const currentTarget = gamesListRef.current;

      if (isInfinitScroll && currentTarget && totalGamesCount > pagePreviewItems) {
        const { bottom } = currentTarget.getBoundingClientRect();
        if (bottom <= window.innerHeight) {
          setPagePreviewItems((prev) => prev + pageSize);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [gamesListRef, isInfinitScroll, totalGamesCount, pagePreviewItems]);

  return (
    <div className={b()}>
      <Container className={b('top-container')}>
        <Breadcrumbs className={b('breadcrumbs')} />
        <PromoSection className={b('promo')} pageName='casino' />
      </Container>

      {isAuth && (
        <Container>
          <CasinoBonusesSection />
        </Container>
      )}


      {isAuth && (
        <CasinoGamesSectionByCategory
          category='favorites'
          title='Избранное'
          link='/games?provider=all&category=all&page=1&search=&sort=ascending&favorites=1'
        />
      )}

      <div className={b('content')}>
        <Container className={b('content-container')}>
          <h2 className={b('title')}>
            {t('casino-widget.all-providers', 'Все провайдеры')}
          </h2>
          {isLoading && <Loader />}
          {!isLoading && (
            <CasinoFilter className={b('filter')}
              providers={providers}
              categories={categories}
              onProviderSelect={handleProviderChange}
              onCategorySelect={handleCategoryChange}
              selectedProvider={selectedProvider}
              selectedCategory={selectedCategory}
              onSearch={handleSearchChange}
              search={search}
              onSort={handleSortChange}
              sort={sort}
              onFavoritesChange={handleFavoritesChange}
              favorites={favoritesSelect}
            />
          )}
          {!isLoading && (
            <div ref={gamesListRef} className={b('list')}>
              {gamesList.slice(listFrom, listTo)
                .map((game) => (
                  <CasinoGameCard
                    className={b('list-item')}
                    key={game.id + game.link}
                    name={game.name}
                    link={game.link}
                    preview={game.preview}
                    gameProvider={game.provaider}
                    isFavorite={game.isFavorite}
                    game={game}
                  />
                ))}
            </div>
          )}
          {!isLoading && !isInfinitScroll && (
            <Pagination className={b('pagination')}
              totalItems={totalGamesCount}
              pageSize={pageSize}
              page={page}
              onChange={(newPage) => setFilter({ page: newPage })}
            />
          )}
        </Container>
      </div>
    </div>
  );
};

export default GamesScreen;
