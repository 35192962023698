import { createSelector } from '@reduxjs/toolkit';
import appApi from './app-api';
import { currenciesTitles } from '../../utils/constants/currency';
import { getPrimaryWallet, getUserData, getUserGeo } from '@/modules/user/store/selectors';
import { NameSpace } from '@/shared/utils/constants/common';
import { AppModal, AppStatus, TBanner, TBannerPage } from './types';
import i18n from '@/shared/i18n/i18n';
import { getStoredCurrencies } from './currencies-localstorage';
import { getStoredBanners } from './banners-localstorage';

export const defaultAppStatus: AppStatus = {
  system: false,
  line: false,
  betgames: false,
  tvbet: false,
  inbet: false,
  vegas: false,
  mascot: false,
  pragmatic: false,
  withdrawals: false,
  agt: false,
  smartsoft: false,
  aviatrix: false,
  fivemen: false,
  endorphina: false,
  digitain: false,
  gosh: false,
  statusMessage: {
    enable: false,
    message: ''
  }
};

export const selectStatusResult = appApi.endpoints.getStatus.select();
export const selectCurrenciesResult = appApi.endpoints.getCurrencies.select();
export const selectWithdrawalMethodsResult = appApi.endpoints.getWithdrawalMethods.select();
export const selectBannersResult = appApi.endpoints.getBanners.select();
export const selectPaymentsMethodsResult = appApi.endpoints.getPaymentsMethods.select();
export const selectAppSportsResult = appApi.endpoints.getAppSports.select();

export const selectAppStatus = createSelector(
  selectStatusResult,
  (statusResult) => statusResult.data ?? defaultAppStatus,
);

export const selectAppSectionStatus = createSelector(
  (_: RootState, section: keyof Omit<AppStatus, 'statusMessage'>) => section,
  selectAppStatus,
  (section, appStatus) => appStatus[section]
);

export const getAppModal = (state: RootState): AppModal =>
  state[NameSpace.App].activeModal;

export const getCurrencies = createSelector(
  selectCurrenciesResult,
  ({ data = [], isLoading }) => {
    const storedCurrencies = getStoredCurrencies();

    return isLoading ? storedCurrencies : data;
  }
);

export const getCurrenciesOptions = createSelector(
  getCurrencies,
  (currencies) => currencies.map((currency) => ({
    value: currency,
    label: currenciesTitles[currency]?.title ?? currency,
  }))
);

export const getWithdrawalMethodsByCurrency = createSelector(
  selectWithdrawalMethodsResult,
  ({ data }) => data,
);

export const getWithdrawalMethods = createSelector(
  getPrimaryWallet,
  getWithdrawalMethodsByCurrency,
  (primaryWallet, withdrawalMethodsByCurrency) =>
    withdrawalMethodsByCurrency?.[primaryWallet?.currency ?? ''] ?? []
);

export const getIsMenuOpened = (state: RootState) =>
  state[NameSpace.App].isMenuOpened;

export const getBanners = createSelector(
  selectBannersResult,
  getUserData,
  getUserGeo,
  (
    { data: banners = [], isLoading },
    { wallet: { currency = '' } },
    { data: userGeo }
  ) => {
    const userCurrency = currency || userGeo?.currency || 'USD';
    const storredBanners = getStoredBanners();
    banners = isLoading ? storredBanners : banners;
    const isBannersLoading = isLoading && storredBanners.length <= 0;
    const sortedBanners = [...banners].sort((a, b) => b.priority - a.priority);
    let filteredBanners = sortedBanners.filter((banner) => banner.currency === null || banner.currency === userCurrency);

    if (filteredBanners.length <= 0) {
      filteredBanners = sortedBanners.filter((banner) => banner.currency === 'UAH');
    }

    const mobile = filteredBanners.filter((banner) => !banner.isDesktop);
    const desktop = filteredBanners.filter((banner) => banner.isDesktop);

    return {
      mobile,
      desktop,
      isLoading: isBannersLoading,
    };
  }
);


const getPageBanners = (items: TBanner[], pageName?: TBannerPage) => {
  if (pageName) {
    return items.filter((item) =>
      item.pages.includes(pageName)
      || item.pages.includes('all')
      || item.pages.length === 0
    );
  }

  return items;
};

export const getBannersByPageName = createSelector(
  getBanners,
  (_: RootState, pageName?: TBannerPage) => pageName,
  ({ mobile, desktop, isLoading }, pageName) => ({
    mobile: getPageBanners(mobile, pageName),
    desktop: getPageBanners(desktop, pageName),
    isLoading
  })
);

export const getTopupMethodsByCurrency = createSelector(
  selectPaymentsMethodsResult,
  ({ data }) => data,
);

export const getTopupMethods = createSelector(
  getPrimaryWallet,
  getTopupMethodsByCurrency,
  (primaryWallet, topupMethodsByCurrency) => {
    const methods = topupMethodsByCurrency?.[primaryWallet?.currency ?? ''] ?? [];


    return [
      {
        id: 'support',
        name: 'support',
        title: i18n.t('topups.methods.operator', 'Через оператора'),
        system: 'support',
        currency: '',
        isActive: true,
        minAmount: 0,
        maxAmount: 0,
        priority: 0,
        icons: ['support'],
      },
      ...methods
    ];
  }
);

export const getBalanceHidden = (state: RootState) => state[NameSpace.App].balanceHidden;

export const getAppSports = createSelector(
  selectAppSportsResult,
  ({ data }) => data
);

export const getSocialLinks = createSelector(
  (state: RootState) => state[NameSpace.App].socialLinks,
  (socialLinks) => socialLinks.filter(({ link }) => !!link)
);

export const getMainLogotoype = (state: RootState) =>
  state[NameSpace.App].mainLogotype;

export const getCouponLogotype = (state: RootState) =>
  state[NameSpace.App].couponLogotype;
